import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Select } from 'antd';
const { Option } = Select;
import { getNonCompats } from '../redux/noncompat';
import { createLocation } from '../redux/location';

const CreateLocation = ({ locations, locationIds, noncompats, getNonCompats, createLocation, onClose }) => {
	const [form] = Form.useForm();
	const [locationOptions, setLocationOptions] = useState(null);
	const [nonCompatOptions, setNonCompatOptions] = useState(null);

	// set up locations
	useEffect(() => {
		if (Array.isArray(locationIds)) {
			const _options = [];
			for (const locId of locationIds) {
				_options.push({
					label: locations[locId].name,
					value: parseInt(locations[locId].id, 10),
				});
			}
			setLocationOptions(_options);
		}
	}, [locations]);

	// setup non-compat types
	useEffect(() => {
		if (noncompats) {
			const _options = [];
			for (const nc of noncompats) {
				const _nonId = parseInt(nc.nonCompatId, 10);
				_options.push({
					label: `${_nonId} - ${nc.name}`,
					value: _nonId,
				});
			}
			setNonCompatOptions(_options);
		}
	}, [noncompats]);

	// 
	useEffect(() => {
		getNonCompats();
	}, [getNonCompats]);

	const onLocationSubmit = async (newLocation) => {
		newLocation = {
			...newLocation,
			hasDogTraining: Boolean(newLocation.hasDogTraining)
		};

		await createLocation(newLocation);
		onClose();
	};

	const validateVetstoriaUrls = (_, value) => {
		const hasVet = form.getFieldValue('hasVet');
		if (parseInt(hasVet) === 1 && !value) {
			return Promise.reject('This field is required when Vet Services are enabled');
		}
		return Promise.resolve();
	};

	return (
		<div>
			<Form
				form={form}
				id="create-location"
				name="create-location"
				onFinish={onLocationSubmit}
				style={{
					maxWidth: 880,
				}}
				scrollToFirstError>
				<Form.Item
					name="locId"
					label="Location ID"
					tooltip="Please enter the Gingr Location ID."
					rules={[
						{
							required: true,
							message: 'Please enter the Gingr Location ID',
						},
					]}>
					<Input />
				</Form.Item>
				<Form.Item
					name="locName"
					label="Name"
					tooltip="Please enter a location name."
					rules={[
						{
							required: true,
							message: 'Please enter a location name.',
						},
					]}>
					<Input />
				</Form.Item>

				<Form.Item
					name="hasVet"
					label="Vet Services"
					rules={[
						{
							required: true,
							message: 'Please select whether this location has VET Services',
						},
					]}>
					<Select placeholder="Enable or Disable?">
						<Option value="1">Yes</Option>
						<Option value="0">No</Option>
					</Select>
				</Form.Item>

				<Form.Item
					name="noncompats"
					label="Non-Compat Types"
					rules={[
						{
							required: true,
							message: 'Please select at least Non-Compat type',
						},
					]}>
					<Select
						placeholder="Select a Non-Compat type"
						mode="multiple"
						allowClear
						options={nonCompatOptions}
					/>
				</Form.Item>

				<Form.Item
					name="hasDogTraining"
					label="Dog Training"
					rules={[
						{
							required: true,
							message: 'Please select whether this location has Dog Training',
						},
					]}>
					<Select placeholder="Enable or Disable?">
						<Option value="1">Yes</Option>
						<Option value="0">No</Option>
					</Select>
				</Form.Item>


				<Form.Item
					name="alternates"
					label="Alternative Locations">
					<Select
						placeholder="Select Alternate locations"
						mode="multiple"
						allowClear
						options={locationOptions}
					/>
				</Form.Item>

				<Form.Item
					name="vetstoriaMobile"
					label="Vetstoria Mobile URL"
					tooltip="Vetstoria URL for Mobile."
					rules={[
						{
							validator: validateVetstoriaUrls,
						},
					]}>
					<Input />
				</Form.Item>

				<Form.Item
					name="vetstoriaWeb"
					label="Vetstoria Web URL"
					tooltip="Vetstoria URL for Web."
					rules={[
						{
							validator: validateVetstoriaUrls,
						},
					]}>
					<Input />
				</Form.Item>

				<Form.Item
					name="merchantId"
					label="Merchant Id"
					tooltip="Merchant ID used for payments. "
					>
					<Input />
				</Form.Item>

			</Form>
		</div >

	);
};

const mapStateToProps = (state) => {
	return {
		...state.locations,
		noncompats: state.noncompats.noncompats
	};
};

const mapDispatchToProps = { getNonCompats, createLocation };

export default connect(mapStateToProps, mapDispatchToProps)(CreateLocation);
