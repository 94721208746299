import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import LocationService from '../services/Locations';
import { normalize, schema } from 'normalizr';

const location = new schema.Entity('locations', undefined, {
	idAttribute: ({ id }) => id,
});

export const getLocations = createAsyncThunk(
	'locations/getAll',
	async () => {
		const locations = await LocationService.getLocations();
		const normalized = normalize(locations, [location]);
		return normalized;
	},
);

export const createLocation = createAsyncThunk(
	'locations/create',
	async (payload, { dispatch }) => {
		const location = await LocationService.create(payload);
		dispatch(getLocations());
		return location;
	},
);

export const updateLocation = createAsyncThunk(
	'locations/update',
	async (payload, { dispatch }) => {
		const location = await LocationService.update(payload);
		dispatch(getLocations());
		return location;
	},
);

export const resetAlternates = createAsyncThunk(
	'locations/resetAlternates',
	async (payload, { dispatch }) => {
		const location = await LocationService.resetAlternaties(payload);
		dispatch(getLocations());
		return location;
	},
);

export const locationsSlice = createSlice({
	name: 'locations',
	initialState: {
		locations: [],
		locationIds: [],
		loading: false,
		selected: null,
		error: null
	},
	reducers: {
		setSelected(state, action) {
			state.selected = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase('locations/getAll/pending', (state) => {
				return { ...state, loading: true };
			})
			.addCase('locations/getAll/fulfilled', (state, action) => {
				return {
					...state,
					locations: action.payload.entities.locations,
					locationIds: action.payload.result,
				};
			})
			.addCase('locations/getAll/rejected', (state, action) => {
				return { ...state, loading: false, error: action.error.message };
			});
	}
});

export const { setSelected } = locationsSlice.actions;

export default locationsSlice.reducer;
