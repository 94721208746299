import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Select } from 'antd';
const { Option } = Select;
import { getNonCompats } from '../redux/noncompat';
import { updateLocation } from '../redux/location';

const EditLocation = ({ selected, locations, locationIds, noncompats, getNonCompats, updateLocation, onClose }) => {
	const [form] = Form.useForm();
	const [locationOptions, setLocationOptions] = useState(null);
	const [nonCompatOptions, setNonCompatOptions] = useState(null);
	const [location, setLocation] = useState(null);

	// set up locations
	useEffect(() => {
		if (Array.isArray(locationIds)) {
			const _options = [];
			for (const locId of locationIds) {
				_options.push({
					label: locations[locId].name,
					value: parseInt(locations[locId].id, 10),
				});
			}
			setLocationOptions(_options);
		}
	}, [locations]);

	// setup non-compat types
	useEffect(() => {
		if (noncompats) {
			const _options = [];
			for (const nc of noncompats) {
				const _nonId = parseInt(nc.nonCompatId, 10);
				_options.push({
					label: `${_nonId} - ${nc.name}`,
					value: _nonId,
				});
			}
			setNonCompatOptions(_options);
		}
	}, [noncompats]);

	// set location from redux
	useEffect(() => {
		if (selected) {
			setLocation(selected);
		}
	}, [selected]);

	// 
	useEffect(() => {
		getNonCompats();
	}, [getNonCompats]);

	const onLocationSubmit = async (loc) => {
		const request = {
			locId: location.id,
			hasVet: loc.hasVet,
			vetstoriaMobile: loc.vetstoriaMobile || '',
			vetstoriaWeb: loc.vetstoriaWeb || '',
			noncompats: loc.noncompats,
			alternates: loc.alternates,
			merchantId: loc.merchantId ? loc.merchantId : undefined,
			hasDogTraining: Boolean(loc.hasDogTraining),
		}
		await updateLocation(request);
		onClose();
	};

	const validateVetstoriaUrls = (_, value) => {
		const hasVet = form.getFieldValue('hasVet');
		if (parseInt(hasVet) === 1 && !value) {
			return Promise.reject('This field is required when Vet Services are enabled');
		}
		return Promise.resolve();
	};

	return (
		<div>
			{location && <Form
				form={form}
				id="edit-location"
				name="edit-location"
				onFinish={onLocationSubmit}
				initialValues={{
					name: location.name,
					address: location.address,
					hasVet: String(location.hasVet),
					vetstoriaMobile: location.vetstoriaMobile,
					vetstoriaWeb: location.vetstoriaWeb,
					alternates: location.alternates.map(alt => alt.locId),
					noncompats: location.noncompats.map(nc => nc.nonCompatId),
					merchantId: location.merchantId,
					hasDogTraining: location.hasDogTraining ? 'Yes' : 'No',
				}}
				style={{
					maxWidth: 880,
				}}
				scrollToFirstError>
				<Form.Item
					name="name"
					label="Name"
					tooltip="This information is pulled from Gingr. Please manage it in Gingr.">
					<Input disabled />
				</Form.Item>

				<Form.Item
					name="address"
					label="Address"
					tooltip="This information is pulled from Gingr. Please manage it in Gingr.">
					<Input disabled />
				</Form.Item>

				<Form.Item
					name="hasVet"
					label="Vet Services"
					rules={[
						{
							required: true,
							message: 'Please select whether this location has VET Services',
						},
					]}>
					<Select placeholder="Enable or Disable?">
						<Option value="1">Yes</Option>
						<Option value="0">No</Option>
					</Select>
				</Form.Item>

				<Form.Item
					name="noncompats"
					label="Non-Compat Types"
					rules={[
						{
							required: true,
							message: 'Please select at least Non-Compat type',
						},
					]}>
					<Select
						placeholder="select a Non-Compat type"
						mode="multiple"
						allowClear
						options={nonCompatOptions}
					/>
				</Form.Item>

				<Form.Item
					name="hasDogTraining"
					label="Dog Training"
					rules={[
						{
							required: true,
							message: 'Please select whether this location has Dog Training',
						},
					]}>
					<Select placeholder="Enable or Disable?">
						<Option value="1">Yes</Option>
						<Option value="0">No</Option>
					</Select>
				</Form.Item>

				<Form.Item
					name="alternates"
					label="Alternative Locations">
					<Select
						placeholder="Aelect Alternate locations"
						mode="multiple"
						allowClear
						options={locationOptions}
					/>
				</Form.Item>

				<Form.Item
					name="vetstoriaMobile"
					label="Vetstoria Mobile URL"
					tooltip="Enter the Vetstoria URL for Mobile."
					rules={[
						{
							validator: validateVetstoriaUrls,
						},
					]}>
					<Input />
				</Form.Item>

				<Form.Item
					name="vetstoriaWeb"
					label="Vetstoria Web URL"
					tooltip="Enter the Vetstoria URL for Web."
					rules={[
						{
							validator: validateVetstoriaUrls,
						},
					]}>
					<Input />
				</Form.Item>

				<Form.Item
					name="merchantId"
					label="Merchant Id"
					tooltip="Merchant ID used for payments. "
					>
					<Input />
				</Form.Item>

			</Form>}
		</div >

	);
};

const mapStateToProps = (state) => {
	return {
		...state.locations,
		noncompats: state.noncompats.noncompats
	};
};

const mapDispatchToProps = { getNonCompats, updateLocation };

export default connect(mapStateToProps, mapDispatchToProps)(EditLocation);
