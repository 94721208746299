import React from 'react';
import '@aws-amplify/ui-react/styles.css';
import 'antd/dist/reset.css';
import './assets/scss/style.scss';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Outlet,
	Navigate
} from 'react-router-dom';
import Locations from './views/Locations';
import NonCompatTypes from './views/NonCompatTypes';
import Minstays from './views/Minstays';
import TopMenu from './components/TopMenu';
import LeftMenu from './components/LeftMenu';
import { Layout, Space } from 'antd';
const { Footer, Sider, Header, Content } = Layout;

function App() {
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<Router>
					<Space direction="vertical" className='layout'>
						<Layout>
							<Sider className='sidebar' collapsible>
								<LeftMenu />
							</Sider>
							<Layout>
								<Header className='header'>
									<TopMenu />
								</Header>
								<Content className='content'>
									<Outlet />
									<Routes>
										<Route path="/" element={<Navigate to="/locations" />} />
										<Route path="/locations" element={<Locations />} />
										<Route path="/noncompats" element={<NonCompatTypes />} />
										<Route path="/minstays" element={<Minstays />} />
									</Routes>
								</Content>
								<Footer>Copyright {new Date().getFullYear()} PetParadise.com</Footer>
							</Layout>
						</Layout>
					</Space>
				</Router>
			</PersistGate>
		</Provider>
	);
}

export default withAuthenticator(App);
