import Network from './Network';

class LocationsService {

	static async getLocations() {
		let response = await Network.request({
			method: 'GET',
			url: '/admin/locations',
		});
		return response.data;
	}

	static async create(payload) {
		let response = await Network.request({
			method: 'POST',
			url: '/admin/locations',
			data: payload
		});
		return response.data;
	}

	static async update(payload) {
		let response = await Network.request({
			method: 'PUT',
			url: '/admin/locations',
			data: payload
		});
		return response.data;
	}

	static async resetAlternaties(payload) {
		let response = await Network.request({
			method: 'PUT',
			url: '/admin/reset-alternates',
			data: payload
		});
		return response.data;
	}

}

export default LocationsService;
